import React from "react";
import './footer.css'
import {InfoBox} from "../shared/info/info";

export const Footer = () => {
    return (
        <>
            <footer className="text-white-50 bg-dark text-center mt-5 mt-sm-5" id="contact">
                <div className="container-fluid pb-3 p-3">
                    <InfoBox/>
                    <div className="row p-3">
                        <div className="col-md-12 text-center text-secondary">
                            &copy; {(new Date().getFullYear())} Restaurant Masel Topf
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="impressumModal" tabIndex={-1} aria-labelledby="impressumModal"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Impressum</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body text-black text-start">
                                <p>
                                    Masel Topf GmbH & Co. KG<br/>
                                    Rykestr. 2<br/>
                                    10405 Berlin<br/>
                                    Email: masel-topf@hotmail.com<br/>
                                    Tel: +49 (0) 30 443 17 525<br/>
                                </p>
                                <p>Geschäftsführer Konstantin Pinski</p>
                                <p>
                                    Bankverbindung:<br/>
                                    Empfänger: Masel Topf GmbH & Co. KG<br/>
                                    IBAN: DE07 1002 0890 0030 2694 62<br/>
                                    BIC: HYVEDEMM488<br/>
                                    Bank: Hypo Vereinsbank<br/>
                                </p>

                                <p>Steuernummer: 27/170/30497</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}