import './App.css';
import {MainMenu} from "./header/mainmenu/mainmenu";
import {Main} from "./section/main/main";
import {Footer} from "./footer/footer";
import React, {Suspense} from 'react';
import {About} from "./section/about/about";


function App() {
    return (
        <>
            <Suspense fallback="loading">
                {/*<ComingSoon />*/}
                <MainMenu/>
                <Main/>
                <About/>
                <Footer/>
            </Suspense>
        </>
    );
}

export default App;
