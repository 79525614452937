import React, {useState} from "react";
import './about.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import {InfoBox} from "../../shared/info/info";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import Carousel from 'react-bootstrap/Carousel';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from "react-bootstrap/esm/Badge";


const Menu = () => {
    const {t, i18n} = useTranslation();

    let main_menu = process.env.PUBLIC_URL;
    if (i18n.language === "en") {
        main_menu += "/speisekarte.pdf";
    } else {
        main_menu += "/speisekarte.pdf";
    }

    let breakfast_menu = process.env.PUBLIC_URL;
    if (i18n.language === "en") {
        breakfast_menu += "/fruehstueck_speisekarte.pdf";
    } else {
        breakfast_menu += "/fruehstueck_speisekarte.pdf";
    }

    return <DropdownButton title={t('menu')} id="bg-nested-dropdown" className="menu-pdf-download w-100" size={"lg"}
                           variant={"outline-light"}>
        <Dropdown.Item href={main_menu} target={"_blank"} className="w-100 text-center">{t('lunch')}</Dropdown.Item>
        <Dropdown.Item href={breakfast_menu} target={"_blank"} className="w-100 text-center"><Badge bg="warning" pill
                                                                                                    text="dark">{t('new')}</Badge> {t('breakfast_menu')}
        </Dropdown.Item>
    </DropdownButton>
}

export const About = () => {
    // const {t, i18n} = useTranslation();


    return (
        <>
            <div className="container-fluid mt-5" id="about">
                <div className="row">
                    <div className="col">
                        <h2 className="text-center">Über Uns</h2>
                    </div>
                </div>
                <div className="row about-content mt-3 mt-sm-5">
                    <div className="col col-sm-6 offset-sm-3">
                        <p>
                            Masel Topf ist ein gemütliches Restaurant im Herzen von Prenzlauer Berg, das eine
                            einzigartige
                            Mischung aus israelische levantinische Fusionsküche bietet und von Stammgästen als eines der
                            besten Restaurants in Berlin geschätzt und empfohlen wird. Masel Topf verwendet nur die
                            frischesten Zutaten von höchster Qualität. Unsere vielfältige Speisekarte umfasst eine
                            Vielzahl
                            von Optionen, von beliebten veganen Gerichten bis hin zu schmackhaften Klassikern, und ist
                            damit
                            perfekt für Frühstück, Mittag- oder Abendessen in Prenzlauer Berg.
                        </p>

                        <p>
                            Unsere warme und einladende Ambiente macht Masel Topf zum idealen Ort für einen entspannten
                            Abend mit Freunden und Liebsten. Wir können unsere klassischen Gerichte wie Shakshuka
                            empfehlen
                            oder einzigartige Optionen wie pochierte Eier auf Roggentoast mit Räucherlachs und Avocado.
                            Jeden Samstag und Sonntag servieren wir von 9:00 bis 16:00 Uhr ein unvergessliches
                            Frühstück,
                            eines der besten Frühstücke in Berlin, wie uns sogar Top-Food-Blogger gesagt haben.
                        </p>

                        <p>
                            An Wochenendabenden verleiht Live-Klaviermusik unserem Restaurant eine besondere Note und
                            sorgt
                            für ein unvergessliches kulinarisches Erlebnis. Wenn Sie auf der Suche nach dem besten
                            Restaurant in Berlin für ein feines Essen sind, laden wir Sie ein, im Masel Topf ein
                            außergewöhnliches kulinarisches Erlebnis zu genießen. Wir freuen uns, Sie begrüßen zu
                            dürfen!
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
};
