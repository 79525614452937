import React from "react";
import {useEffect} from 'react';
import logo from '../../logo.png';
import './mainmenu.css'
import {useTranslation} from 'react-i18next';

export const MainMenu = () => {

    const {t, i18n} = useTranslation();

    useEffect(() => {

        let widget = document.getElementById('quandoo-booking-widget')
        if (widget != null) {
            widget.innerHTML = ""
        }

        const script = document.createElement('script');
        script.src = "https://booking-widget.quandoo.com/index.js";
        script.setAttribute('data-merchant-id', '4326')
        script.setAttribute('data-theme', 'light')
        script.setAttribute('data-primary-color', 'f5b016')
        script.setAttribute('data-lang', i18n.language)
        script.async = false

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [i18n.language]);

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top" id="top-navbar">
                <div className="container-md">
                    <a className="navbar-brand" href="#">
                        <img src={logo} className="d-inline-block align-items-center" alt="logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">Über Uns</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">Kontakt</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};


