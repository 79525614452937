import React from "react";
import {useTranslation} from "react-i18next";
import './info.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";


export const InfoBox = () => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="row p-4 contact">
                <div className="col-md-4 mt-5 mt-md-1 text-center align-items-center">
                    <h4 className="text-white-50">{t('open_hours_title')}</h4>
                    <div className="text-white open-hours">
                        {t('open_hours_1')}
                    </div>
                    <div className="text-white open-hours mt-2">
                        {t('open_hours_2')}
                    </div>
                </div>

                <div className="col-md-4 mt-5 mt-md-1 contact">
                    <h4 className="text-white-50">{t('contact')}</h4>
                    <p className="m-1">
                        <a href="tel:+493044317525" className="text-white">+49 (0) 30 443 17 525</a>
                    </p>
                    <p className="m-1">
                        <a href="mailto:masel-topf@hotmail.com" className="text-white">masel-topf@hotmail.com</a>
                    </p>
                    <p className="m-1">
                        <a href="#" data-bs-toggle="modal"
                           className="nav-link"
                           data-bs-target="#impressumModal">Impressum</a>
                    </p>

                </div>
                <div className="col-md-4 mt-5 mt-md-1 mb-5 mb-md-0">
                    <h4 className="text-white-50">{t('address')}</h4>
                    <a href="https://maps.app.goo.gl/G8wWVqUE3vQxXctC7" target="_blank">
                        <div>
                            <div>Rykestraße 2</div>
                            <div>10405 Berlin</div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}