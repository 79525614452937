import React, {useState, useEffect} from "react";
import './main.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import {InfoBox} from "../../shared/info/info";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import Carousel from 'react-bootstrap/Carousel';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from "react-bootstrap/esm/Badge";
import hImg from '../../img/full/main_img.jpeg'
import vImg1 from '../../img/mobile/1.jpg'
import vImg2 from '../../img/mobile/2.jpg'
import vImg3 from '../../img/mobile/3.jpg'


const Menu = () => {
    const {t, i18n} = useTranslation();

    let main_menu = process.env.PUBLIC_URL;
    if (i18n.language === "en") {
        main_menu += "/speisekarte.pdf";
    } else {
        main_menu += "/speisekarte.pdf";
    }

    let breakfast_menu = process.env.PUBLIC_URL;
    if (i18n.language === "en") {
        breakfast_menu += "/fruehstueck_speisekarte.pdf";
    } else {
        breakfast_menu += "/fruehstueck_speisekarte.pdf";
    }

    return <DropdownButton title={t('menu')} id="bg-nested-dropdown" className="menu-pdf-download w-100" size={"lg"}
                           variant={"outline-light"}>
        <Dropdown.Item href={main_menu} target={"_blank"} className="w-100 text-center">{t('lunch')}</Dropdown.Item>
        <hr className="separator"/>
        <Dropdown.Item href={breakfast_menu} target={"_blank"} className="w-100 text-center"><Badge bg="warning" pill
                                                                                                    text="dark">{t('new')}</Badge> {t('breakfast_menu')}
        </Dropdown.Item>
    </DropdownButton>
}

export const Main = () => {
    const {t, i18n} = useTranslation();

    const [pause, setPause] = useState<false | "hover">(false);
    const [imgs, setImgs] = useState([hImg]);
    const handleResize = () => {
        if (window.innerHeight > window.innerWidth && imgs.length == 1) {
            setImgs([vImg1, vImg2, vImg3])
        } else {
            setImgs([hImg])
        }
    }
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [])

    const open_hours = [
        {
            day: "Montag",
            from: 12,
            to: 24
        },
        {
            day: "Dienstag",
            from: 12,
            to: 24
        },
        {
            day: "Mittwoch",
            from: 12,
            to: 24
        },
        {
            day: "Donnerstag",
            from: 12,
            to: 24
        },
        {
            day: "Freitag",
            from: 12,
            to: 24
        },
        {
            day: "Samstag",
            from: 9,
            to: 24
        },
        {
            day: "Sonntag",
            from: 9,
            to: 24
        },
    ];

    return (
        <>
            <header className="main-section">
                <Carousel fade pause={pause} controls={imgs.length > 1} indicators={imgs.length > 1}>
                    {imgs.map((img, i) =>
                        <Carousel.Item interval={5000} key={i}>
                            <img src={img} alt="img" className="w-100 h-auto"/>
                            <div className="overlay"></div>
                            <Carousel.Caption>
                                <div className="d-flex justify-content-center h-100">
                                    <div className="container text-center open-hours-main">
                                        <div className="row">
                                            <div className="col-12 text-center open-hours-title">Öffnungszeiten</div>
                                        </div>
                                        {open_hours.map(time =>
                                            <div className="row open-hours-entry">
                                                <div
                                                    className="col-3 col-sm-5 offset-sm-0 offset-2 align-self-center">
                                                    {time.day}
                                                </div>
                                                <div className="col-2 col-sm-2 align-self-center">
                                                    <hr/>
                                                </div>
                                                <div className="col-3 col-sm-5 align-self-center">
                                                    {time.from}<i className="bi bi-dash"></i>{time.to}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row full-width-button mt-5">
                                            <div className="col-8 offset-sm-0 offset-2 col-sm-12">
                                                <div onMouseEnter={() => setPause("hover")}
                                                     onMouseLeave={() => setPause(false)} className="w-100"><Menu/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row full-width-button mt-3">
                                            <div className="col-8 offset-sm-0 offset-2 col-sm-12">
                                                <a href="#" data-bs-toggle="modal"
                                                   data-bs-target="#bookTableModal">
                                                    <button className="btn btn-outline-light btn-lg w-100"
                                                            type="button">{t('book')}</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )}


                    {/*<Carousel.Item interval={5000}>*/}
                    {/*    <Carousel.Caption>*/}
                    {/*        <div className="d-flex justify-content-center align-items-center h-100">*/}
                    {/*            <div onMouseEnter={() => setPause("hover")}*/}
                    {/*                 onMouseLeave={() => setPause(false)}><Menu/></div>*/}
                    {/*            <a href="#" data-bs-toggle="modal" className="m-2"*/}
                    {/*               data-bs-target="#bookTableModal">*/}
                    {/*                <button className="btn btn-outline-light btn-lg "*/}
                    {/*                        type="button">{t('book')}</button>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </Carousel.Caption>*/}
                    {/*</Carousel.Item>*/}
                    {/*<Carousel.Item interval={5000}>*/}
                    {/*    /!*<Carousel.Caption>*!/*/}
                    {/*    /!*    <div className="d-flex justify-content-center align-items-center flex-column h-100">*!/*/}
                    {/*    /!*        <div className="d-flex caption-text rounded fw-bold p-3 position-relative">*!/*/}
                    {/*    /!*            <p className="text-white text-center fs-5 mb-0">{t('breakfast')}*!/*/}
                    {/*    /!*            </p>*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*        <div className="d-flex">*!/*/}
                    {/*    /!*            <div onMouseEnter={() => setPause("hover")}*!/*/}
                    {/*    /!*                 onMouseLeave={() => setPause(false)}><Menu/></div>*!/*/}
                    {/*    /!*            <a href="#" data-bs-toggle="modal" className="m-2"*!/*/}
                    {/*    /!*               data-bs-target="#bookTableModal">*!/*/}
                    {/*    /!*                <button className="btn btn-outline-light btn-lg "*!/*/}
                    {/*    /!*                        type="button">{t('book')}</button>*!/*/}
                    {/*    /!*            </a>*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</Carousel.Caption>*!/*/}
                    {/*</Carousel.Item>*/}
                </Carousel>

                {/*<div*/}
                {/*    className="container-fluid text-center text-white fixed-bottom contact-info d-md-block d-none"*/}
                {/*    id={"overlayInfoBox"}>*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="container-md">*/}
                {/*                <InfoBox/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </header>

            <div className="modal fade" id="bookTableModal" tabIndex={-1} aria-labelledby="bookTableModal"
                 aria-hidden="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h5 className="modal-title text-black">{t('book')}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="model-body d-flex justify-content-center p-1">
                            <div id="quandoo-booking-widget"></div>
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="modal fade" id="impressumModal" tabIndex={-1} aria-labelledby="impressumModal"*/}
            {/*     aria-hidden="true" role="dialog">*/}
            {/*    <div className="modal-dialog modal-fullscreen-md-down modal-dialog-scrollable" role="document">*/}
            {/*        <div className="modal-content">*/}
            {/*            <div className="modal-header">*/}
            {/*                <h5 className="modal-title text-black">Impressum</h5>*/}
            {/*                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>*/}
            {/*            </div>*/}
            {/*            <div className="modal-body text-black text-start">*/}
            {/*                <p>*/}
            {/*                    Masel Topf GmbH & Co. KG<br/>*/}
            {/*                    Rykestr. 2<br/>*/}
            {/*                    10405 Berlin<br/>*/}
            {/*                    Email: masel-topf@hotmail.com<br/>*/}
            {/*                    Tel: +49 (0) 30 443 17 525<br/>*/}
            {/*                </p>*/}
            {/*                <p>Geschäftsführer Konstantin Pinski</p>*/}
            {/*                <p>*/}
            {/*                    Bankverbindung:<br/>*/}
            {/*                    Empfänger: Masel Topf GmbH & Co. KG<br/>*/}
            {/*                    IBAN: DE07 1002 0890 0030 2694 62<br/>*/}
            {/*                    BIC: HYVEDEMM488<br/>*/}
            {/*                    Bank: Hypo Vereinsbank<br/>*/}
            {/*                </p>*/}

            {/*                <p>Steuernummer: 27/170/30497</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
        ;
};
